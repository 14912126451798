<template>
  <div>
    <header-view/>
    <b-container>
      <stepper-view :steps="steps" :step="step"/>
      <router-view/>
    </b-container>
    <footer-view/>
  </div>
</template>

<script>
import HeaderView from "@/components/layout/Header"
import StepperView from "@/components/common/Stepper"
import FooterView from "@/components/layout/Footer"
import { mapState } from "vuex";

export default {
  name: "Index",
  components: {
    HeaderView,
    StepperView,
    FooterView
  },
  computed: {
    ...mapState({
      steps: (state) => state.common.steps,
      step: (state) => state.common.step,
    }),
  },
}
</script>

<style lang="scss">
.container {
  padding: 0 10px;
}
.container:has(div.thanks-page-content) {
  padding: 0;
}

@media (min-width: 576px) {
  .container-sm:has(div.thanks-page-content), .container:has(div.thanks-page-content) {
    max-width: unset !important;
  }
}
</style>
