<template>
  <div class="d-flex main-header align-items-center">
    <div
      class="content-header d-flex justify-content-between align-items-center"
    >
      <img
        v-if="isPolicyOrTerms"
        src="@/assets/images/logo_header.svg"
        @click="logoHeaderLP"
      />
      <img
        v-else
        src="@/assets/images/logo_header.svg"
        @click="logoHeader"
        style="cursor: pointer"
      />
      <div class="d-flex align-items-center text-white contact">
        <a href="mailto:info@autoflip.com.au" class="d-flex mr-12">
          <img src="@/assets/images/icons/ic_newmail.svg" class="cursor-pointer" />
          <p class="mb-0 fs-14">info@autoflip.com.au</p>
        </a>
        <a href="tel:1300816821" class="d-flex">
          <img src="@/assets/images/icons/ic_telphone.svg" />
          <p class="mb-0 fs-14">1300 816 821</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { MAIN_PAGE_URL, AUTOFLIP_URL } from "../../helper/constant";
export default {
  name: "Header",
  data() {
    return {
      isPolicyOrTerms: false,
    };
  },
  methods: {
    logoHeader(e) {
      e.preventDefault();
      window.open(AUTOFLIP_URL, "_blank")
    },
    logoHeaderLP() {
      window.location.href = MAIN_PAGE_URL
    }
  },
  created() {
    if (
      this.$route.name === "privacy-policy" ||
      this.$route.name === "terms-and-conditions" ||
      this.$route.name === "photo-guide"
    ) {
      this.isPolicyOrTerms = true;
    }
  },
};
</script>

<style lang="scss">
.main-header {
  width: 100%;
  height: 75px;
  background-color: #ffffff;
  padding: 0 10px 0;

  .content-header {
    width: 100%;

    img {
      width: 157px;
      height: 30px;
    }

    .contact {
      a {
        color: #000;
        text-decoration: none;
      }

      img {
        width: 25px !important;
        height: 25px !important;
        margin-right: 12px;
      }

      p {
        display: none;
        margin-top: auto;
      }
    }
  }
}

@media screen and(min-width: 768px) {
  .content-header {
    max-width: 1099px;
    margin: 0 auto;

    img {
      width: 210px !important;
      height: 40px !important;
      margin-left: 0;
    }

    .contact {
      a:last-child {
        margin-left: 37px;
      }
      p {
        display: block !important;
      }
    }
  }
}
</style>
