<template>
  <div
    v-if="this.$route.name !== 'thanks-page'"
    class="step d-flex justify-content-center align-items-center mx-auto"
  >
    <div class="progress">
      <div
        class="progress-bar"
        :class="
          this.steps[this.progressStep].progress > 0 &&
          this.steps[this.step].active
            ? `w-${this.steps[this.progressStep].progress}`
            : 'w-0'
        "
        role="progressbar"
      ></div>
    </div>
    <div class="progress-percent ml-2 font-weight-thin">
      <span class="text-black">{{ `${steps[progressStep].progress}%` }}</span>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/common";
import { mapState } from "vuex";
import debounce from "lodash/debounce";
export default {
  name: "Stepper",
  props: ["steps", "step"],
  mixins: [mixin],
  computed: {
    ...mapState({
      curStep: (state) => state.common.step,
      progressStep: (state) => state.common.progressStep,
    }),
  },
  mounted() {
    this.watchMethod();
  },
  methods: {
    watchMethod: debounce(function() {
      this.$store.dispatch("watchStep", this.curStep);
    }, 500),
  },
};
</script>

<style lang="scss">
.step {
  width: inherit;
  padding: 1.25rem !important;
  .progress {
    height: 7px !important;
    width: 90%;
    background-color: #C4C4C4 !important;
    .progress-bar {
      background-color: #1f8252;
      border-top-right-radius: 40px !important;
      border-bottom-right-radius: 40px !important;
    }
  }
}

@media screen and(min-width: 1200px) {
  .step {
    padding: 24px 37px 0px !important;
  }
}

@media screen and(max-width: 1199px) {
  .step {
    padding: 24px 47px 0px !important;
  }
}

@media screen and(max-width: 991px) {
  .step {
    padding: 24px 60px 0px !important;
  }
}

@media screen and(max-width: 767px) {
  .step {
    padding: 24px 16px 0px !important;
  }
}

@media screen and(max-width: 450px) {
  .step {
    padding: 20px 22px 0px !important;
  }
}
</style>
